import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  type OutlinedInputProps,
} from '@mui/material';
import * as React from 'react';

interface State {
  password: string;
  showPassword: boolean;
}

const initialState = {
  password: '',
  showPassword: false,
};

type PasswordInputProps = OutlinedInputProps & {
  // require autoComplete
  autoComplete: OutlinedInputProps['autoComplete'];
};

export function PasswordInput(props: PasswordInputProps) {
  const [values, setValues] = React.useState<State>(initialState);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      // these are the defaults
      fullWidth
      id="password"
      name="password"
      required={true}
      onChange={handleChange('password')}
      type={values.showPassword ? 'text' : 'password'}
      value={values.password}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {values.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      //   spread the props if users want to override them
      {...props}
    />
  );
}
